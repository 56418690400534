import * as React from "react"
import Layout from "../components/layout"
import { BaseImage, DuoImage } from "../components/photos"
import * as styles from "./index.module.scss"
import p1 from "../images/jcch/1.png"
import p2 from "../images/jcch/2.png"
import p3 from "../images/jcch/3.png"
import p4 from "../images/jcch/4.png"
import p5 from "../images/jcch/5.png"
import p6 from "../images/jcch/6.png"
import p7 from "../images/jcch/7.png"
import p8 from "../images/jcch/8.png"
import p9 from "../images/jcch/9.png"
import p10 from "../images/jcch/10.png"
import p11 from "../images/jcch/11.png"
import p12 from "../images/jcch/12.png"
import p13 from "../images/jcch/13.png"
import p14 from "../images/jcch/14.png"
import p15 from "../images/jcch/15.png"
import p16 from "../images/jcch/16.png"
import p17 from "../images/jcch/17.png"
import p18 from "../images/jcch/18.png"
import p19 from "../images/jcch/19.png"
import p20 from "../images/jcch/20.png"
import p21 from "../images/jcch/21.png"
import p22 from "../images/jcch/22.png"
import p23 from "../images/jcch/23.png"
import p24 from "../images/jcch/24.png"
import p25 from "../images/jcch/25.png"
import p26 from "../images/jcch/26.png"
import p27 from "../images/jcch/27.png"
import p28 from "../images/jcch/28.png"

class JasonCaseyCityHall extends React.Component {
    render() {
        return (
            <Layout title="Portraits - Jason/Casey">
                <div className={styles.photoBlog}>
                    <p>
                        <a href="/">
                            Home
                        </a>
                        <h3>Jason and Casey</h3>
                        <div>City Hall - July 2022</div>
                    </p>
                    <BaseImage imgSrc={p1} />
                    <DuoImage imgSrc={p2} imgSrc2={p3} />
                    <BaseImage imgSrc={p4} />
                    <BaseImage imgSrc={p5} />
                    <BaseImage imgSrc={p6} />
                    <BaseImage imgSrc={p7} />
                    <DuoImage imgSrc={p8} imgSrc2={p9} />
                    <BaseImage imgSrc={p10} />
                    <BaseImage imgSrc={p11} />
                    <DuoImage imgSrc={p12} imgSrc2={p13} />
                    <BaseImage imgSrc={p14} />
                    <BaseImage imgSrc={p15} />
                    <BaseImage imgSrc={p16} />
                    <BaseImage imgSrc={p17} />
                    <DuoImage imgSrc={p18} imgSrc2={p19} />
                    <BaseImage imgSrc={p20} />
                    <BaseImage imgSrc={p21} />
                    <BaseImage imgSrc={p22} />
                    <BaseImage imgSrc={p23} />
                    <BaseImage imgSrc={p24} />
                    <BaseImage imgSrc={p25} />
                    <DuoImage imgSrc={p26} imgSrc2={p27} />
                    <BaseImage imgSrc={p28} />
                    

                </div>
            </Layout>
        )
    }
}

export default JasonCaseyCityHall
